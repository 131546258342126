@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
body {
  font-family: "Roboto", sans-serif;
}

:root {
  --amplify-fonts-default-variable: "Roboto", sans-serif;
  --amplify-fonts-default-static: "Roboto", sans-serif;
  --font-family: "Roboto", sans-serif;
  --amplify-font-family: "Roboto", sans-serif;
  --amplify-primary-color: #002c9a;
  --amplify-primary-tint: #002c9a;
  --amplify-primary-shade: #002c9a;
  --amplify-background-color: #ffffff;
}
