@font-face {
  font-family: "ValueSans";
  src: local("ValueSans"),
    url(./assets/fonts/value-sans-regular.otf) format("woff");
}

@font-face {
  font-family: "ValueSans";
  font-weight: 500;
  src: local("ValueSans"),
    url(./assets/fonts/value-sans-medium.otf) format("woff");
}

@font-face {
  font-family: "ValueSerif";
  src: local("ValueSerif"),
    url(./assets/fonts/value-serif-regular.otf) format("woff");
}

@font-face {
  font-family: "ValueSerif";
  font-weight: 500;
  src: local("ValueSerif"),
    url(./assets/fonts/value-serif-medium.otf) format("woff");
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  font-family: ValueSans, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*Override mui table expandable width*/
.css-14a6ia4-MuiTableCell-root{
  width: 24px !important;

  /* vertical-align: bottom !important; */
  /* padding-bottom: 1rem !important; */
}
